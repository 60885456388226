import { Box } from "@mui/material";
import { FlexBox, FooterButton, FullWidth, Section } from "./DualMenu.style";
import { useBreak } from "hooks/useBreak";
import { useLocation, useNavigate } from "react-router-dom";
import { getFocus } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { Icon } from "assets/Icons_";
import { SelectFunction } from "components/SelectFunction/SelectFunction";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { Controls } from "components/Isometry/Isometry.Controls";
import { getIsoData, getLowIsoData, setIsoData } from "state/jotai.isometryDataHooks";
import EusButton, { ETStyledButton } from "components/Button/EusButton";
import { ArrowButton } from "components/Isometry/Isometry.Controls.Arrows";
import { PoweredBy } from "components/PoweredBy/PoweredBy";

export const OverviewDualMenu = ({ left, right, nav, pin, openSelectFunction, showSelectFunction, setShowSelectFunction, clickList, list, buttonsLabel, showProperty, setShowProperty, toggleDrawer, config, rotatemode, click2, turnBuilding, isrotate, click1, clickModel,showOldSlider}) => {
    const type = getFocus(_bootstrap, "type");

    const isMobile = useBreak("md_dn");
    const here = useLocation().pathname;
    const isIsometry = here.includes("isometry")

    const frame = getIsoData("activeframe");
    const frames = getIsoData("frames");
    const index = getIsoData("snapindex");
    const setIndex = setIsoData("snapindex");
    const setFrame = setIsoData("activeframe");
    const percent = getIsoData('percent')

    const lowQualityPercent = getLowIsoData('percent')
    const lowQualityConfig  = getLowIsoData('config')

    const showButton = [
        "list",
        "compare",
        "favorites",
        "gallery",
        "downloads",
      ].some((i) => here.includes(i));
    
    const props = { type, isIsometry, pin , clr}
    return <>
        <FullWidth
          isMobile={isMobile}
          left={left}
          right={right}
          nav={nav}
          clr={clr}
          isBgTrue={isMobile ? true: true}
        >
              <FlexBox clr={clr} > 
            {
                isMobile ? 
                <>
                    {/* <Section clr={clr} onClick={()=> {
                    if(showSelectFunction) {
                        setShowSelectFunction(false)
                    } else openSelectFunction()
                }} padding="20px">
                    <Icon
                        icon="ellipsisvertical"
                        color={clr.primary_text}
                        isFaIcon={true}
                        size="xl"
                        wrap={{ transform: showSelectFunction ? 'rotate(90deg)' : 'rotate(0deg)'}}
                    />
                    <SelectFunction
                        click1={click1}
                        click2={click2}
                        isrotate={isrotate}
                        turnBuilding={turnBuilding}
                        rotatemode={rotatemode}
                        showSelectFunction={showSelectFunction}
                        setShowSelectFunction={setShowSelectFunction}
                    />
                </Section> */}
                </>
                :
                <>
                    <Box
                    sx={{
                        width: isMobile && !isIsometry ? '100%' : "auto",
                        display: "flex",
                        justifyContent: isMobile && !isIsometry ? "space-between" : "flex-start",
                        gap: "16px",
                        position: "relative",
                    }}
                    >
                    { !turnBuilding &&
                        // <ETStyledButton
                        //     variant={"primary"}
                        //     clr={clr}
                        //     // boxShadow={true}
                        //     pin={pin}
                        //     type={type}
                        //     isIsometry={isIsometry}
                        //     isMobile={isMobile}
                        //     onClick={()=> {
                        //         if(!showSelectFunction) {
                        //             // openSelectFunction()
                        //             setShowSelectFunction(true);
                        //         } else {
                        //             setShowSelectFunction(false);
                        //             const buttons = document.querySelectorAll("button");
                        //                 buttons.forEach((button) => button.blur());
                        //         }
                        //     }
                        // }
                        //     padding={"0px"}
                        //     width={"44px"}
                        // >
                        // <span
                        //     style={{
                        //         fontSize: "22px", // This will enlarge the icon
                        //         display: "inline-flex", // Ensures proper alignment
                        //     }}
                        //     >
                        //     <Icon
                        //         icon="ellipsisvertical"
                        //         color={'#000'}
                        //         isFaIcon={true}
                        //         size="xl"
                        //         skipColorChange={false}
                        //         wrap={{ transform: showSelectFunction ? 'rotate(90deg)' : 'rotate(0deg)'}}
                        //     />
                        //     </span>

                        //     <SelectFunction
                        //         click1={click1}
                        //         click2={click2}
                        //         isrotate={isrotate}
                        //         turnBuilding={turnBuilding}
                        //         rotatemode={rotatemode}
                        //         showSelectFunction={showSelectFunction}
                        //         setShowSelectFunction={setShowSelectFunction}
                        //     />
                        // </ETStyledButton>
                        <></>
                    }
                         {!turnBuilding && (
                        <EusButton
                        {...props}
                        clr         = {clr}
                        active      = {here.includes("list")}
                        onClick     = {clickList}
                        variant     = {"primary"}
                        iconName    = {"list"}
                        icon        = {true}
                        buttonText  = "Boligliste"
                        buttonlabel = {true}
                        iconSize    = {"xl"}
                        isFaIcon    = {true}
                        skipColorChange={true}
                      />
                         )}
                            {!turnBuilding && (
                        <ETStyledButton
                        variant = {"primary"}
                        pin     = {pin}
                        skipColorChange={true}
                        onClick = {() => {
                          if (!showProperty) {
                            setShowProperty(true);
                            toggleDrawer();
                          } else {
                            setShowProperty(false);
                            toggleDrawer();
                            const buttons = document.querySelectorAll("button");
                            buttons.forEach((button) => button.blur());
                          }
                              // setShowProperty(!showProperty);
                              // toggleDrawer();
                        }}
                        clr        = {clr}
                        showButton = {showButton}
                      >
                        <Icon
                          icon  = "buildings"
                          size  = "lg"
                          // color = {
                          //   pin > 0 || showButton ? "#000": clr?.primary_text
                          // }
                          skipColorChange ={true}
                          color = {clr?.primary_text}
                          isFaIcon = {true}
                        />
                        <div
                          className = "eus-button-2"
                          style     = {{ color: clr?.primary_text }}
                        >
                          Skift ejendom
                        </div>
                        <ShiftProperty
                        {...props}
                          showProperty    = {showProperty}
                          setShowProperty = {setShowProperty}
                        />
                      </ETStyledButton>
                            )}
                        {(config?.IMAGE_SNAPFRAMES?.length > 0) && isIsometry && !isMobile && !showOldSlider && turnBuilding && (
                            <ETStyledButton
                            tabIndex={"0"}
                            role="button"
                            showButton={showButton}
                            {...props}
                              variant={"secondary-1st-varient"}
                            onClick={rotatemode && isrotate ? click1 : click2}
                            >
                            <>
                                <Icon
                                icon={isrotate ? "pause" : "play"}
                                color={"#000"}
                                isFaIcon={true}
                                size="lg"
                                />{" "}
                                    <p style={{ color: "#000" }}>{isrotate ? "Stop rotation" : "Start rotation"}</p>
                            </>
                            </ETStyledButton>
                        )}
                    </Box>
                    <Box>
                        {/* <Section width="none">
                            {!rotatemode && lowQualityPercent == 100 && (
                                <>
                                {lowQualityConfig?.IMAGE_SNAPFRAMES?.length > 1 && (
                                    <Controls
                                    {...{
                                        frame,
                                        setFrame,
                                        config: lowQualityConfig,
                                        frames,
                                        index,
                                        setIndex,
                                    }}
                                    />
                                )}
                                </>
                            )}
                        </Section> */}
                    </Box>
                </>
            }
             
            </FlexBox>
            <PoweredBy clr = "#000" padding = { isMobile ? "0px 0 16px 0" : "0px 0 8px 0"} />
        </FullWidth>
    </>
}