// import { MenuItem } from "@mui/material";
import { Divider, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const DropdownItem = ({ children, showDivider, selected,padding, ...props }) => {
  return (
    <>
    <MenuItem
      {...props}
      sx={{
        padding: padding,
        color: selected ? clr.buttons : "inherit",
        // backgroundColor: selected ? "rgba(217, 217, 217, 0.35)" : "none",
        // borderBottom:"1px solid white"
      }}
    >
      {children}
    </MenuItem>
    <div style={{padding:"0 24px" }}>
     { showDivider && <Divider style={{margin:"0px" ,borderBottom: "1px solid #000"  }} />}
     </div>
     </>

  );
};

DropdownItem.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
};

DropdownItem.defaultProps = {};

export default DropdownItem;
