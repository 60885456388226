import { Main, Item } from "components/List/Grid/Grid.style";
import { Content } from "components/List/Grid/Grid.Content";
import { get, floorplan, getImages, getThumb } from "components/List/List.Utilities";
import { FLOORPLAN } from "api/api";
import { active_unit, vis_desktop_nav  } from "state/store.global";
import { useAtom } from "state/jotai";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { ImgWrapper } from "components/ImgContainer/ImgWrapper";
import { useLocation } from "react-router-dom";

export const Grid = ({ units, extra, pick, click }) => {
  const geta = useAtom(active_unit)[0];
  const [show, setShow] = useAtom(vis_desktop_nav);

  const here                              = useLocation().pathname;
  const atUnit                            = here.includes("unit");

  return (
    <>
      <Main atUnit={atUnit}>
        {/* {extra?.map((i, j) => {
          const onClick = () => click(i.UnitID);
          const thumbnail = floorplan(i, FLOORPLAN);
          const item = { key: i?.UnitID, style: pick, onClick };
          const content = { unit: i, thumbnail, color: get(i, "UnitColor") };

          const isActive = geta.UnitID === i.UnitID;
          const color = isActive
            ? { background: `${get(i, "UnitColor")}66` }
            : {};

          const sliderImage = getImages(i, true) ?? []
          const sliderThumbImage = getThumb(i, true) ?? []

          const props = { item, color: get(i,'UnitColor'), style: color, sliderImage, sliderThumbImage, content, i, unitId: i.UnitID }
          return (
              <MainWrapper {...props} />
          );
        })} */}
        {units?.map((i, j) => {
            const onClick   = () => {click(i.UnitID); setShow(false);}
            const thumbnail = floorplan(i, FLOORPLAN);
          const item = { key: i?.UnitID, onClick };
          const content = { unit: i, thumbnail, color: get(i, "UnitColor") };

          const isActive = geta.UnitID === i.UnitID;
          const color = isActive
            ? { background: `${get(i, "UnitColor")}66` }
            : {};

          const sliderImage = getImages(i, true) ?? []
          const sliderThumbImage = getThumb(i, true) ?? []

          const props = { item, color: get(i,'UnitColor'), style: color, sliderImage, sliderThumbImage, content, i, atUnit,  unitId: i.UnitID }

          return (
            <MainWrapper {...props} />
          );
        })}
      </Main>
    </>
  );
};


const MainWrapper = ({ item, color, style, sliderImage, sliderThumbImage, i, content, atUnit, unitId }) => {
  
  const [ref, isIntersecting] = useIntersectionObserver({ threshold: 0.1 });

  return <div id={`unitid_${unitId}`} ref={ref} key={i}>
            <Item {...item} color={color} style={style} atUnit={atUnit}>
              <Content {...content} isIntersecting={isIntersecting} />
            </Item>
          </div>
}
