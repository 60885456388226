import styled, { css } from 'styled-components/macro';
import { _project } from 'state/store.projects';
import { a_labHovered, active_project, currentActiveProject, isMulti_project_active, iso_free, iso_isrotating, vis_overlay } from 'state/store.global';
import { active_unit } from 'state/store.global';
import { useGet, useAtom, getFocus } from 'state/jotai';
import { useUnit, usePick } from './Isometry.utilities';
import { motion, AnimatePresence } from 'framer-motion';
import { open_drawer } from 'state/actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dropdownOptions } from 'components/Dropdown2/Dropdown2.Data';
import { useEffect, useRef, useState } from 'react';
import { useBreak } from 'hooks/useBreak';
import { _bootstrap } from 'state/store.bootstrap';
import { getIsoData, getLowIsoData } from 'state/jotai.isometryDataHooks';
import { Overview } from 'components/OverviewLabel/Overview';

export const Svg = ({ svg, percent, units, showTooltip, hideTooltip ,status,zppRef,scale,setScale}) => {
  const { pin, pid } = useGet(active_project);
  const multiActive = useAtom(isMulti_project_active)[0];
  const setActiveProject = useAtom(currentActiveProject)[1];
  const is_labHovered = useAtom(a_labHovered)[0];
  const type = useGet(_bootstrap).type;
  
  const vbox = svg?.attributes?.viewBox;
  const blur = Math.abs(percent - 100) / 3;
  const opac = percent / 100;
  const draw = useAtom(open_drawer)[1];
  const geta = useAtom(active_unit)[0];
  const seta = useAtom(active_unit)[1];
  const show = useGet(vis_overlay);
  const pick = usePick();
  const unit = useUnit();

  const { i18n: { language } } = useTranslation();
  const index = getIsoData('snapindex');
  // const status = getIsoData('status');
  const config = getIsoData('config');
  const rmode = useGet(iso_free);
  const navigate = useNavigate();
  const isMobile = useBreak('md_dn');
  const [showLabel, setShowLabel] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);

  const isrotating = useGet(iso_isrotating);

  const lowQualityStatus = getLowIsoData('status');

  useEffect(() => {
    setShowLabel(false);
  }, [pin, pid]);

  return (
    <>
      <AnimatePresence>
        {/* {console.log(lowQualityStatus , "lowquaitu" , percent ,"percent")} */}
        {lowQualityStatus == "DONE" && units && percent === "100" && show && (
          <svg viewBox={vbox} style={{ position: 'absolute', willChange: 'transform' }}>
            {
              svg?.children?.map(({ attributes: { id, d } }, index) => {
                const ID = id?.replace(/nr/g, '').split('-')[0];
                const address = isNaN(ID) ? '' : unit(ID, 'Adresse');
                const sID = isNaN(ID) ? '' : unit(ID, 'UnitStatusID');
                const sName = isNaN(ID) ? '' : unit(ID, 'UnitStatusName');
                let color = isNaN(ID) ? '#ffffff00' : unit(ID, 'UnitColor', 'f');
                if (ID === "Kommer_snart") {
                  color = "#443F3980";
                }
                const picked = isNaN(ID) ? '' : pick(ID);
                const UnitID = isNaN(ID) ? '' : unit(ID, 'UnitID');
                const Unit = isNaN(ID) ? '' : unit(ID, 0, 'i');
                const isActive = geta?.UnitID === UnitID;
// console.log(ID,"iddd")
                const onClick = (e) => {
                  if (ID === "Kommer_snart") {
                    isMobile && showTooltip(e);
                    return null;
                  }
                  e.stopPropagation();
                
                  if (type === "poly" && pin === 0 && isMobile) {
                    setShowLabel(true);
                  } else {
                    if (UnitID === false) return;
                
                    if (isNaN(ID)) {
                      if (ID === "albanikarreen" || ID ==="fisketorvkarreen" || ID ==="norregadehuset" ) {
                        setActiveProject(ID);
                        navigate(`/${language}/isometry/${ID}`);
                      }
                    }
                
                    if (sID == 40 || sID == 30) return;
                
                    if (sID !== 40 && multiActive) {
                      seta(Unit);
                      draw('detail');
                      log({ action: 'clicked_apartment_on_isometry', unitId: UnitID });
                    }
                  }
                };
                
                

                const style = { filter: `blue(${blur}px)`, opacity: isNaN(ID) ? '1' : opac };
                const key = d;
                const path = { d, id, key, style, onClick, sID, sName, color, picked, active: isActive, ID, is_labHovered };

                if (!d.includes('M0'))
                  return (
                    <Path {...path}
                      onMouseEnter={(evt) => {
                        !isMobile && setShowLabel(true);
                        !isMobile && ID === "Kommer_snart" && showTooltip(evt);
                        setHoveredId(ID);  // Set the hovered ID
                      }}
                      onMouseLeave={() => {
                        !isMobile && hideTooltip();
                        setShowLabel(false);
                        setHoveredId(null);  // Clear the hovered ID
                      }}
                    />
                  );
              })}
          </svg>
        )}
      </AnimatePresence>
      {
        !isrotating &&
      //    <div
      //   style={{
      //     transform: `scale(${1 / (zppRef.current?.state.scale || 1)})`,
      //     transformOrigin:  `left top`,
      //     width: '100%',
      //     height: '100%',
      //     pointerEvents: 'none', // Prevents interactions
      //     position: 'relative', // Keeps canvas in place
      //   }}
      // > 
        <Overview  {...{ scale,index, rmode, status: lowQualityStatus, showLabel, setShowLabel, hoveredId,zppRef }} />
        //  </div>
      }
    </>
  );
};

export const Path = styled.path( ({sID,color,picked,active,ID,is_labHovered}) => css`
  cursor: ${(() => {

    return sID == 40 ? 'not-allowed' : 'pointer';
  })()};
  fill  : ${ (() => {
    if(typeof is_labHovered?.name === 'string' && is_labHovered?.name?.toUpperCase() === ID?.toUpperCase()) return '#ffffff80'
    if ( active ) return '#FFFFFF90'
    if ( picked ) return '#FF00FF'
    if ( color?.value == 'err' ) {
      if ( sID == 10 ) return '#00ff0080'
      if ( sID == 20 ) return '#ffff0080'
      if ( sID == 30 ) return '#0000ff80'
      if ( sID == 40 ) return '#ff000080'
    }
    if ( isNaN(ID) ) {
      return color
    }
    return ( typeof color === 'string' ) 
      ? color + '80' 
      :  '#00000080'
  })() };




  ${ typeof color !== 'string' } {
    : hover { fill: #ffffff80; }
  }
`)

