import { useEffect, useState } from "react";
import {
  Svg,
  Circle,
  Pos,
  Lab,
  Outer,
  Text1,
  Text2,
  CircleGroup,
  LabContainer,
} from "./Overview.label.style";

import { KeepScale } from "react-zoom-pan-pinch";

import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { a_labHovered, active_project } from "state/store.global";
import { useClick } from "./Overview.label.click";
import { _project } from "state/store.projects";
import { getFocus, useGet } from "state/jotai";
import { useBreak } from "hooks/useBreak";
import { _bootstrap } from "state/store.bootstrap";

export const Label = ({ scale, c, sc, d, l, rmode, r, labelsInfo, line, angle, mobileLabelAxis, showLabel, setShowLabel, hoveredId }) => {
  const isMobile = useBreak("md_dn");
// console.log(labelsInfo,"skskk")
  const { pin, pid } = useGet(active_project);
  const unitStatus = getFocus(_project, "unitStatuses")?.[pin]?.[pid] ?? [];
  const availableTranslation = unitStatus?.filter((u) => u.ID === 10)?.[0]?.Name ?? "";

  const projectUnitsStatus = getFocus(_bootstrap, 'projects');
  const options = getFocus(_project, "filteroptions");
  const PID =  labelsInfo?.PID;
 
  const PIN = labelsInfo?.PIN;
  const unitsCounts = projectUnitsStatus?.filter((p) => p?.ProjectID === PID);
  
  // const totalUnits = unitsCounts?.[0]?.NoOfSalesUnits;
  const totalUnits = unitsCounts?.[0]?.NoOfRentalUnits === 0 ? unitsCounts?.[0]?.NoOfSalesUnits : unitsCounts?.[0]?.NoOfRentalUnits
  
  const allUnits = getFocus(_project, 'units');

  const roomSize = options[PIN]?.[PID]?.filter((p) => p?.Label === "Størrelse")?.[0];
  const noOfRooms = options[PIN]?.[PID]?.filter((p) => p?.Label === "Værelser")?.[0];
  const moveInDate = allUnits?.[PIN]?.[PID]?.Units?.map((u) => u?.Data?.Indflytningsdato?.UnformattedValue)?.filter(date => date);
  const hardcodedMoveInDate = allUnits?.[PIN]?.[PID]?.Units?.[0]?.Data?.Indflytningsdatoproperty?.UnformattedValue;
  const here = useLocation().pathname.split("/");

  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  
  const isOverview = here[here.length - 1] === "isometry";

  const [notHovered, setNotHovered] = useState(true);

  const click = useClick();

  const s = 0.6 / sc === Infinity ? 10 : 0.6 / sc;
  const cx = r?.[0];
  const cy = r?.[1];

  const dl = { d, l, s, notHovered };

  useEffect(() => {
    if (hoveredId === labelsInfo?.URLSafe) {
      setNotHovered(false);
    } else {
      setNotHovered(true);
    }
  }, [hoveredId ]);
  
  if (isOverview && !rmode) {
    return (
      <Pos  c={c} style={{
        position: "absolute",
        // transform: `scale(${1.2})`
      }}>
        {/* Rest of your component code */}
        
        <div>
          
        <Svg viewBox="0 0 100 100">
          <CircleGroup transform={`translate(${cx} ${cy})`}>
            <Circle cx={0} cy={0} r={15} clr={clr} />
            <Circle cx={0} cy={0} r={25} fillOpacity="0.5" clr={clr} />
            <Circle cx={0} cy={0} r={35} fillOpacity="0.3" clr={clr} />
          </CircleGroup>
        </Svg>
        {/* <KeepScale > */}
        {hoveredId == labelsInfo?.URLSafe? (  
          <Outer scale={scale} {...dl} xAxis={isMobile ? mobileLabelAxis?.[0] : ""} yAxis={isMobile ? mobileLabelAxis?.[1] : ""}>
          {/* <Outer {...dl} style={{ position: "fixed", left: `${20}px`, top: `${20}px` }}> */}
            <LabContainer {...dl} clr={clr} onClick={!isMobile ? () => click(labelsInfo) : null}>
              <Text2 style={{ borderBottom: "2px solid #fff", padding: "8px 16px", fontSize: '40' }}>
                {labelsInfo?.Presentation == "c4" ?  buttonsLabel?.filter((btn) => btn.UILabelProperty === "Enhedertilleje")[0]?.Label : "Boliger til leje"} 
              </Text2>
              <Lab clr={clr}>
                <Text1 className="h3" s={s}>{labelsInfo?.Presentation}</Text1>
                <Text2 className="paragraph2-regular">
                  {totalUnits}&nbsp;
                  {totalUnits > 1
                    ? buttonsLabel?.filter((btn) => btn.UILabelProperty === "boliger")[0]?.Label.toLowerCase()
                    : buttonsLabel?.filter((btn) => btn.UILabelProperty === "bolig")[0]?.Label.toLowerCase()}
                </Text2>
                <Text2 className="paragraph2-regular">
                  {labelsInfo?.available_Units}{" "}
                  {labelsInfo?.available_Units > 1
                    ? buttonsLabel?.filter((btn) => btn.UILabelProperty === "Ledige")[0]?.Label
                    : availableTranslation?.toLowerCase()}
                </Text2>
                <Text2 className="paragraph2-regular">{`${roomSize?.MinValue} - ${roomSize?.MaxValue} ${roomSize?.Postfix}`}</Text2>
                <Text2 className="paragraph2-regular"> 
                  {`${noOfRooms?.MinValue} - ${noOfRooms?.MaxValue}`}{" "}
                  {noOfRooms?.MaxValue > 1
                    ? buttonsLabel?.filter((btn) => btn.UILabelProperty === "værelser")[0]?.Label
                    : buttonsLabel?.filter((btn) => btn.UILabelProperty === "værelse")[0]?.Label}
                </Text2>
                {/* {hardcodedMoveInDate && (
                  <Text2 className="paragraph2-regular">
                    {buttonsLabel?.filter((btn) => btn.UILabelProperty === "Indflytning")[0]?.Label}: {hardcodedMoveInDate}
                  </Text2>
                )} */}
              </Lab>
            </LabContainer>
          </Outer>
        ) : (
          <Outer scale={scale} {...dl} xAxis={isMobile ? mobileLabelAxis?.[0] : ""} yAxis={isMobile ? mobileLabelAxis?.[1] : ""}>
            <LabContainer {...dl} clr={clr} onClick={!isMobile ? () => click(labelsInfo) : null}>
            <Text2 className="paragraph2-regular" style={{  padding: "8px 16px" }}>
            {labelsInfo?.Presentation == "c4" ?  buttonsLabel?.filter((btn) => btn.UILabelProperty === "Enhedertilleje")[0]?.Label : "Boliger til leje"} 
              </Text2>
            </LabContainer>
          </Outer>
        )}
        {/* </KeepScale> */}
        </div>
        
      </Pos>
    );
  }

  return null; // Return null or another component if conditions are not met
};
