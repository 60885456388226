import { List } from 'components/List/List'
import { DualMenu } from 'components/DualMenu/DualMenu'
import { TopMenu } from 'components/TopMenu/TopMenu'
import { useBreak } from 'hooks/useBreak'
import { Scrollbar } from 'react-scrollbars-custom'
import { useAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import { active_unit } from 'state/store.global'
import { useLocation } from 'react-router-dom'

export const ViewList = () => {
  const isDesktop = useBreak('md_up')
  const active = useAtom(active_unit)[0]
  const here = useLocation().pathname

  const hasScrolled = useRef(false); // Ref to keep track if scrolling has happened

  useEffect(() => {
    // Scroll to the active unit when the component mounts, but only once
    const scrollToActiveUnit = () => {
      const currActiveUnitId = active?.UnitID;
      if (currActiveUnitId) {
        const activeUnitElement = document.getElementById(`unitid_${currActiveUnitId}`);
        if (activeUnitElement) {
          activeUnitElement.scrollIntoView({ behavior: "smooth", block: "end"  });
        }
      }
    };

    if (!hasScrolled.current) {
      scrollToActiveUnit();           // Trigger the scroll only once
      hasScrolled.current = true;     // Set the flag to true so it won't scroll again
    }
  }, [active]);  // Trigger scroll when the active unit changes

  return (
    <>
      {
        isDesktop ?
          <Scrollbar trackYProps={{
            renderer: (props) => {
              const { elementRef, ...restProps } = props;

              return <span {...restProps} ref={elementRef}
                     className="trackY" style={{ ...restProps.style }} />;
            },
          }}>
            <RenderComponent />
          </Scrollbar>
          : 
          <RenderComponent />
      }
    </>
  )
}

const RenderComponent = () => {
  return (
    <>
      <List />
    </>
  )
}
