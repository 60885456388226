import { forwardRef } from 'react'
import styled from 'styled-components/macro'

export const Measure = forwardRef( ( { children}, ref ) => {

  return (
    <>
      <Box ref={ref}>
        { children }
      </Box>
    </>
  )
})

const Box = styled.div`
  height:100%; 
  position:relative;
  transition: right 0.3s ease;
  /* border:10px solid blue; */
`