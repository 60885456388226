import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { Menu, Tile, Split, Text } from './Nav.Desktop.Foldout.style';
import { Icon } from 'assets/Icons_';
import { Link } from 'react-router-dom';
import { MENU } from 'constants';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useTranslation } from 'react-i18next';
import { _project } from "state/store.projects";
import { active_project, params_query } from "state/store.global"; 
import { getFocus, useGet } from "state/jotai";
import { PoweredBy } from 'components/PoweredBy/PoweredBy';
import { getProjectData } from 'state/jotai.projectDataHooks';
import { useWindowSize } from 'hooks/useWindowResize';

export const Foldout = ({ nav, show, setShow }) => {
  const params       = useGet(params_query);
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [menuHeight, setMenuHeight]       = useState(null);

  const window       = useWindowSize();
  const { pin, pid } = useGet(active_project);
  const menus        = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];
  const downloads    = getProjectData('downloads', 0, []);
 
  const { i18n: { language } } = useTranslation();
  const buttonsLabel           = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
  const menuRef = useRef();
  
  const menu = {
    initial   : { x: +MENU.DESKTOP_NAV_DRAWER_W + 'px' },
    animate   : { x: 300 + 'px' },
    exit      : { x: +MENU.DESKTOP_NAV_DRAWER_W + 'px' },
    transition: { duration: 0.3, ease: 'linear' },
  };
  
  const animation = {
    initial   : { opacity: 0 },
    animate   : { opacity: 1 },
    exit      : { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  const dynamicFilter = (item, downloadsLength) => {
    // Exclude items with Order "10" regardless of downloads length
    if (item.Order === "10") return false;
    
    // Exclude items with Order "9" if downloads length is 0
    if (downloadsLength === 0 && item.Order === "9") return false;
    
    // Include all other items
    return true;
  };

  const uniqueItems = new Set();
  const menusItemsFiltered = menus
    .filter((item) => dynamicFilter(item, downloads.length))
    .filter((item) => {
      const isDuplicate = uniqueItems.has(item.Text);
      uniqueItems.add(item.Text);
      return !isDuplicate;
    })
    
    .map((items, index) => {
      const item = {};
      for (const key in items) {
        if (items.hasOwnProperty(key)) {
          item[key.toLowerCase()] = items[key];
        }
      }
      return item;
    });
    // console.log(menusItemsFiltered,"sjsjsjj")
  useEffect(() => {
    const bottomPadding   = 96;
    const footerBarHeight = 72;
    const height          = menuHeight || menuRef?.current?.clientHeight;

    if (menuRef.current && ((height + bottomPadding + footerBarHeight) > window.height)) {
      !menuHeight && setMenuHeight(menuRef.current.clientHeight);
      isMenuVisible && setIsMenuVisible(false);
    } else if (menuHeight < window.height) {
      !isMenuVisible && setIsMenuVisible(true);
    }
  }, [window]);
  
  return (
    <AnimatePresence>
      { show && 
        <Menu 
          as={motion.div}
          clr={clr} 
          {...animation}
          ref={menuRef}
        >
          <div 
            style={{
              color: clr?.primary_text,
              padding: "16px", 
              borderBottom: `1px solid ${clr.primary_text}`, 
              display: 'flex', 
              justifyContent: "space-between", 
              alignItems: "center"
            }}
          >
            <div className='h5' style={{width:"100%", display:"flex", justifyContent:"center"}}>
              {
                buttonsLabel?.filter(
                  (btn) => btn?.UILabelProperty === "Hvorvildugernehen"
                )[0]?.Label
              }
            </div>
            <span className='icon-hover-effect '>
            <Icon 
              icon='close'
              isFaIcon={true} 
              click={() => setShow(false)} 
              size="xl" 
              color={clr?.primary_text}
            />
            </span>
          </div>

          <div style={{
            padding: "32px 24px 0px 24px", 
            height: isMenuVisible ? "auto" : "calc(100vh - 255px)", 
            overflow: 'auto'
          }}>
          { 
            menusItemsFiltered.map(({url, text, icon}, j) => {
              const ico = {href: url[language], icon, size: "16", color: clr?.primary_text};
              const URL = (url.includes('gallery') || url.includes('downloads')) && params.length ? url + params : url;
              return ( 
                <div 
                  key={text} 
                  onClick={() => setShow(false)}  
                >
                  <Link 
                    clr={clr} 
                    to={URL} 
                    key={text} 
                    style={{textDecoration:'none'}}
                  >
                    <Tile index={j} clr={clr}>
                    <span className='icon-hover-effect' style={{display:"flex",gap:"8px"}}>
                      <Icon 
                        {...ico} 
                        isFaIcon={false} 
                        FaIconColor={clr.primary_text}
                      />
                      <Text className='paragraph1-regular' clr={clr}>{text}</Text>
                      </span>
                    </Tile>
                  </Link>
                  <Split clr={clr}/>
                </div>
              );
            })
          }
          </div>
          <PoweredBy clr={clr.primary_text} padding='8px 0 16px 0px' />
        </Menu>
      }
    </AnimatePresence>
  );
};
