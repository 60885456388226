import { useLayoutEffect, useState } from 'react';
import { getFocus, useGet } from 'state/jotai';
import { _bootstrap } from 'state/store.bootstrap';
import { active_project } from 'state/store.global';

export const useScalePoint = (bounds, dimensions, mode, isMobile) => {
  const [res, setRes] = useState(0);

  const { pin, pid } = useGet(active_project)
  const projectType  = getFocus(_bootstrap, 'type')

  useLayoutEffect(() => {
    if (bounds.width) {
      if(projectType === 'poly' && pin === 0) {
        // Scale based only on width to ensure full horizontal coverage
        // const scaleFactor = bounds.width / dimensions.W;
        const longestDimension = bounds.width >= bounds.height ? 'width' : 'height'
        const R_map    = (dimensions.W/dimensions.H).toFixed(4)
        const R_bounds = (bounds.width/bounds.height).toFixed(4)
  
        const scaleFactor = (()=>{
  
          if ( R_bounds > R_map || R_bounds < 1 ) {
            if ( longestDimension === 'width' ) return bounds.width  / dimensions.W
            if ( longestDimension === 'height') return bounds.height / dimensions.H
          } else {
            if ( longestDimension === 'width' ) return bounds.height / dimensions.H
            if ( longestDimension === 'height') return bounds.width  / dimensions.W
          }
  
        }
      )()
        setRes(scaleFactor);
      }else {
        const R_map    = ( dimensions.W / dimensions.H  ).toFixed(4)
        // const R_bounds = ( bounds.width / bounds.height ).toFixed(4)
        const R_bounds = ( (bounds.width / bounds.height) - ( isMobile ? (pin>0 ? 0 : 0 ) : (pin>0 ? 0 : 0 )) ).toFixed(4)
  
        const operator = mode ? ( R_bounds < R_map ) : ( R_bounds > R_map )
        
        const scaleFactor = ( operator )
        ? (bounds.width  / dimensions.W) - ( isMobile ? (pin>0 ? 0 : 0 ) : (pin>0 ? 0 : 0 ))
            : bounds.height / dimensions.H
        setRes( scaleFactor )
      }
    }
  }, [bounds, dimensions]);

  return res || 1; // Return a default scale factor of 1 if not calculated yet
};
