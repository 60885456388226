import { BREAK_ } from "constants";
import styled, { css, keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
`;

export const Svg = styled.svg`
  // width: 100px;
  // height: 100px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  pointer-events: none;
  z-index: 10; // Set highest z-index for Svg
`;
export const Circle = styled.circle`
  fill: ${({ clr }) => clr.alternative_condition};
  animation: ${pulseAnimation} 1s infinite;
`;
export const CircleGroup = styled.g`
  &:hover {
    animation: ${pulseAnimation} 1s infinite;
  }
`;

export const Group = styled.g``;

export const Line = styled.line`
  stroke: #fff;
  stroke-width: 3;
`;
export const Path = styled.path`
  stroke: ${({ clr }) => clr.primary_text};
  stroke-width: 2;
`;
export const Pos = styled.div(
  ({ c, s }) => css`
    pointer-events: none;
    position: absolute;
    left: ${c?.[0]}px;
    top: ${c?.[1]}px;
    width: 0px;
    height: 0px;
  `
);
export const LabContainer = styled.div(
  ({ d, l, s }) => css`
    // padding: 5px 10px;
    // background: #232935de;
    background: ${({ clr }) => `${clr.primary}e6`};
    transform: ${`rotate(-${d}deg) translate(-50%,-50%)`};
    transform-origin: left top;
    color: #000;
    left: ${l}px;
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    //   border-radius: ${25 * s}px;
    // padding: ${15 * s}px ${100 * s}px;
    // padding: 20px 30px;
    justify-content: center;
    align-items: center;
    // gap: 5px;
    flex-direction: column;
    cursor: pointer;
    pointer-events: none;
    border: 2px solid #fff;
    border-radius: 8px;
    z-index: 5; // Lower z-index for LabContainer
    ${BREAK_.md_dn} {
      pointer-events: auto;
      z-index: 5; // Lower z-index for LabContainer
    }
  `
);

export const Lab = styled.div`
  // padding: 5px 10px;
  // background: #232935de;
  // background: ${({ clr }) => `${clr.primary}e6`};
  // transform: ${`rotate(-${d}deg) translate(-50%,-50%)`};
  // transform-origin: left top;
  // left: ${l}px;
  // top: 0;
  // position: absolute;
  // border-radius: ${25 * s}px;
  // padding: ${15 * s}px ${100 * s}px;
  // border: 2px solid #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  cursor: pointer;
  pointer-events: none;
  border-radius: 8px;
  ${BREAK_.md_dn} {
    padding: 8px 16px;

    background: ${({ clr }) => `${clr.primary}e6`};
    // transform: ${`rotate(-${d}deg) translate(-50%,-50%)`};
    transform-origin: left top;
    // left: ${l}px;
    top: 0;
    // position: absolute;
    // border: 3px solid #fff;
    pointer-events: auto;
  }
`;

export const Outer = styled.div(
  ({ d, l, xAxis, yAxis, notHovered, scale }) => css`
    width: ${l * 2}px;
    height: ${l * 2}px;
    top: ${notHovered ? "60px" : `${xAxis}px`};
    left: ${yAxis}px;
    border: 1px solid transparent;
    border-radius: 50%;
    pointer-events: none;
    background: transparent;
    position: absolute;
    transform-origin: left top;
    /* transform: ${`rotate(${d}deg) translate(-50%, -50%)`}; */
    transform: ${`rotate(${d}deg) translate(-50%, -50%) scale(${1 / scale})`};
    z-index: 10; // Lower z-index for LabContainer
    ${BREAK_.md_dn} {
      pointer-events: auto;
      transform: ${`rotate(${d}deg) translate(-50%, -50%)`};
      /* transform: ${`rotate(${d}deg) translate(-50%, -50%) scale(${1 / scale})`}; */
    }
  `
);
export const Text1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
  font-size: 16px;
  ${BREAK_.md_dn} {
    font-size: 14px;
  }
  font-weight: 600;
  letter-spacing: 0.25px;
  color: #000;
  pointer-events: none;
`;
export const Text2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
  font-size: 14px;
  ${BREAK_.md_dn} {
    font-size: 14px;
  }
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #000;
  pointer-events: none;
  // padding: 1rem;
`;
