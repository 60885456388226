import styled from 'styled-components/macro'
import { MENU } from 'constants'

export const Menu = styled.div`
  // height: 100%;
  background-color: ${({ clr }) => clr.primary};
  color: ${({ clr }) => clr.primary_text};
  display: flex;
  flex-direction: column;
  a { text-decoration: none; }
  left:0;
  top:0;
  z-index: 3;
`
export const Vertical = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index:5;


`
export const Icon3DEffect = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: perspective(500px) rotateX(45deg) scale(0.9);
  }
`;
export const LogoWrap = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  /* border:1px solid black; */
`