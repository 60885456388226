import eachOfLimit                from 'async/eachOfLimit'
import { useEffect }              from 'react'
import { useFocusSet, useGet }    from 'state/jotai'
import { useFetch }               from 'hooks/fetch/useFetch'
import { _lowerQualityIsometry }              from 'state/store.lowerQualityIsometry'
import { _bootstrap }             from 'state/store.bootstrap'
import { array }                  from 'utilities/utility.misc'
import { preloadImage }           from 'utilities/utility.images'
import { ISO_FRAMES, ISO_CONFIG } from 'api/api'
import { active_project } from 'state/store.global'

export const PreloadFramesLowerQuality_Mono = () => {

  const type   = useGet(_bootstrap).type
  const file   = 'main/Mono/MonoPreload.jsx'
  const mono   = type === 'mono'
  const url    = ISO_CONFIG
  const config = useFetch({ url, file }).D
  const cache  = useGet(_lowerQualityIsometry).status

  const { pin, pid } = useGet(active_project)

  const setFrames  = useFocusSet(_lowerQualityIsometry, 'frames')
  const setPercent = useFocusSet(_lowerQualityIsometry, 'percent')
  const setStatus  = useFocusSet(_lowerQualityIsometry, 'status')
  const setConfig  = useFocusSet(_lowerQualityIsometry, 'config')

  useEffect(()=>{
    if ( pin !== null && pin >=0 && config && !['FETCHING','DONE'].includes(cache?.[pin]?.[pid]) ) {
      const i   = config[pin]
      const PID = i.PROJECT_ID
      const NUM = i.IMAGE_NUM
      const INI = i.FIRST_IMAGE
      const EXT = i.FILE_EXT
      const SUB = i.LOWER_QUALITY_PNG_PATH
              // const FNC = x => `${ISO_FRAMES}/${PID}/${x + INI}.${EXT}`
      const FNC = x => `${ISO_FRAMES}${SUB}/${x + INI}.${EXT}`
      const COL = array(NUM, FNC)
              // console.log( COL )
      const ARR = []
      // setConfig( [ { [PID] : i } ] )
      setConfig ( a => a?.map((d,j)=>fn(d,j,pin,pid,i)) )
      eachOfLimit(COL, 20,
        ( val, idx, callback ) => {
          const IMG     = preloadImage(val)
          const STATUS  = ( idx !== NUM - 1 ) ? 'FETCHING' : 'DONE'
          const PERCENT = ( idx * ( 100 / (NUM-1)) ).toFixed()
          ARR   [idx]   = { [INI+idx]:IMG }
          // setPercent( (percent)=> [ { ...percent, [PID] : PERCENT } ] ) // mono code
          // setFrames ( (frames)=> [ { ...frames, [PID] : ARR     } ] )
          // setStatus ( (status)=> [ { ...status, [PID] : STATUS  } ] )
          setFrames ( a => a?.map((d,i)=>fn(d,i,pin,pid,ARR     )) ) // poly code
          setPercent( a => a?.map((d,i)=>fn(d,i,pin,pid,PERCENT )) )
          setStatus ( a => a?.map((d,i)=>fn(d,i,pin,pid,STATUS  )) )
          IMG.onload = ( ) => callback()
        },
        () => {
                  // console.log( 'isometries for mono project eido finished' )
        }
      )
    }
  },[mono, config, pin, pid])

  return null
}
        // Preload.whyDidYouRender = true

const fn = (x,y,z,v,w) => (y===z) ? { [v] : w } : x