import { useEffect } from 'react'
import { useScalePoint } from 'hooks/useScalePoint' 
import { useBreak } from 'hooks/useBreak'

export const createZppSetup = (bounds, ref, dimensions, setScale, mode, pin) => {
  const isMobile = useBreak('md_dn');
  
  // Calculate scale based on the window's width so that the image fits entirely
  const scale = useScalePoint(bounds,dimensions,mode,isMobile) + ( isMobile ? (pin> 0 ? 0.03 : -0.2 ) : (pin>0 ? -0.04 : -0.17));
 
  useEffect(() => {
    // Center the image and zoom to fit the full width
    ref.current?.zoomToElement('center', scale, 0, null);
    setScale(scale ? scale : 1);
  }, [scale, bounds, mode]);

  const wrapperProps = {
    wheel: { step: 0.02 ,smoothStep:0.002 },
    panning            : { velocityDisabled: true },
    limitToBounds: true,
    alignmentAnimation: { sizeX: 0, sizeY: 0 }, // Align image perfectly within bounds
    zoomAnimation: { size: 0 },
    minScale: scale ?? 0,
    maxScale: scale ? 7 : 0,
    onInit: (event) => event.zoomToElement('center', scale, 0, null),
    onZoom: (event) => setScale(event.state.scale),
    // centerOnInit: true,
  };

  const wrapperStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: !isMobile && pin === 0 ? "-20px" : "0",
    // overflow: 'hidden',
    willChange: 'transform',
  };

  const contentStyle = {
    width: dimensions.W,
    height: dimensions.H,
    // overflow: 'hidden',
    willChange: 'transform',
  };

  return {
    wrapperProps,
    wrapperStyle,
    contentStyle,
  };
};
