import { Box, Stack } from "@mui/material";                                                             // Importing Material-UI components
import { useContactDrawer } from "components/Contact/useContactDrawer";                                 // Custom hook to manage contact drawer
import PropTypes from "prop-types";                                                                     // Prop types for type checking
import React, { useState } from "react";                                                                // React core functions
import { useTranslation } from "react-i18next";                                                         // For i18n support
import { useNavigate, useLocation } from "react-router-dom";                                            // For navigation and URL location
import { getFocus, useAtom, useGet } from "state/jotai";                                                // Jotai state management hooks
import { active_project, params_query, previousUrl } from "state/store.global";  // Jotai state atoms for global store
import { animateScroll } from "react-scroll";                                                           // For smooth scrolling within the page
import useUnitPage from "./useUnitPage";                                                                // Custom hook to manage unit page logic
import { useBreak } from "hooks/useBreak";                                                              // Custom hook for screen size detection
import styled, { css } from "styled-components";
import { toggle_drawer } from "state/actions";
import { _project } from "state/store.projects";
import { darken } from "polished";

function NewReservationButton({ unit }) {
  const location       = useLocation();
  const here           = location.pathname;
  const atUnit         = here.includes("unit");
  const [refContainer] = useUnitPage();
  const isDesktop      = useBreak("md_up");

  const { open }     = useContactDrawer();
  const navigateTo   = useNavigate();
  const params       = useGet(params_query);
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const {
    i18n: { language },
  } = useTranslation();
  const [, toggleDrawer]   = useAtom(toggle_drawer);
  const [, setPreviousUrl] = useAtom(previousUrl);

  const [selectedButton, setSelectedButton] = useState(
    atUnit ? "contactUs": "readMore"
  );

  const handleMore = (e) => {
    const buttons = document.querySelectorAll("button");
    buttons.forEach((button) => button.blur());
    setPreviousUrl(location.pathname);
    navigateTo(
      `/${language}/unit/${unit.getId()}${params.length ? params : ""}`
    );
    toggleDrawer();
    setSelectedButton("readMore");
  };

  const handleClick = (e) => {
    const buttons = document.querySelectorAll("button");
    buttons.forEach((button) => button.blur());
    const targetElement = document.getElementById("unit_page_contact_form");

    if (isDesktop) {
      refContainer.scrollTo(0, targetElement.offsetTop);
    } else if (targetElement) {
      const offset = targetElement.offsetTop;
      animateScroll.scrollTo(offset, { container: refContainer });
    }
  };

  const handleContact = (e) => {
    const buttons = document.querySelectorAll("button");
    buttons.forEach((button) => button.blur());
    open(unit);
    setSelectedButton("contactUs");
  };

  const handleBookView = () => setSelectedButton("bookViewing");
  const handleSignUp   = () => setSelectedButton("signUp");

  return (
    <Box   sx      = {{ padding: atUnit ? "" : "24px 32px" }}>
    <Stack spacing = {2}>
        {!atUnit && (
          <CustomButton
            variant = { "primary" }
            onClick = {handleMore}
              // atUnit={atUnit}
            clr = {clr}
          >
            <span className = "eus-button-2">
              {
                buttonsLabel?.filter(
                  (btn) => btn?.UILabelProperty === "Læsmereomenhed2"
                )[0]?.Label
              }
            </span>
          </CustomButton>
        )}
        <CustomButton
      variant={selectedButton === "contactUs" && atUnit? "primary" : "secondary"}
          onClick = {atUnit ? handleClick : handleContact}
          atUnit  = {atUnit}
          clr     = {clr}
        >
          <span className = "eus-button-2">
            {
              buttonsLabel?.filter(
                (btn) => 
                  btn?.UILabelProperty === 
                  (atUnit ? "kontaktOs" : "kontaktOs")
              )[0]?.Label
            }
          </span>
        </CustomButton>
      </Stack>
    </Box>
  );
}

export default NewReservationButton;

  // Custom styled button component
export const CustomButton = styled.button`
  padding      : ${({ padding }) => (padding ? padding : "12px 40px")};
  border-radius: 6px;
  cursor       : ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  box-sizing   : border-box;
  height       : 44px;
  opacity      : ${({ disabled }) => (disabled ? 0.5 : 1)};

  color: ${({ variant, atUnit, clr }) => {
    if (atUnit) {
      return clr?.buttons_text || clr?.primary_text;
    }
    return variant === "primary" ? "white" : "black";
  }};

  background-color: ${({ variant, clr }) =>
    variant === "primary" ? `${clr?.buttons}` : clr?.buttons_text};

  border: ${({ variant }) =>
    variant === "primary" ? `1px solid ${clr?.buttons}` : "1px solid #D1D1D1"};

  transition: background-color 0.1s ease-in, border 0.1s ease-in,
    box-shadow 0.1s ease-in;

  &:hover {
    background-color: ${({ variant, clr, disabled }) => {
      if (disabled) {
        return "";  // Disabled hover background
      }

      if (variant === "primary") {
        return darken(0.05, clr?.buttons);
      }
        // if (disabled) {
        //   return ``;
        // }
      return   darken(0.05, clr?.buttons_text);
    }};

    border: ${({ variant }) =>
      variant === "primary"
        ? `1px solid ${clr?.buttons}`
        : "1px solid #D1D1D1"};
  }

  &:focus {
    background-color: ${({ variant, clr, disabled }) => {
      if (disabled) {
        return " ";  // Disabled focus background
      }
      if (variant === "primary") {
        return darken(0.05, clr?.buttons);
      }
      return clr?.buttons_text;
    }};
    box-shadow: ${({ variant }) =>
      variant === "secondary" ? "0 0 0 1.5px #D1D1D1" : `0 0 0 1.5px ${clr?.buttons}`};
  }
  &:disabled {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

  // border: ${({ variant }) =>
  //   variant === "primary" ? `2.5px solid ${clr.buttons}` : "1px solid #D1D1D1"};

  // box-shadow: ${({ variant }) =>
  //   variant === "secondary" ? "0 0 0 1.5px #D1D1D1" : "none"};
  // }

  //   &:disabled {
  //     background-color: #B0BEC5;
  //     color: #ECEFF1;
  //     border: 2px solid transparent;
  //   }
