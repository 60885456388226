import { useRef, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useMeasure from 'react-use-measure';
import { _isometry } from 'state/store.isometry';
import { active_project, current_language, vis_showOldSlider } from 'state/store.global';
import { iso_free } from 'state/store.global';
import { iso_isrotating } from 'state/store.global';
import { getFocus, setFocus, useAtom, useGet } from 'state/jotai';
import { getIsoData, getLowIsoData, setIsoData } from 'state/jotai.isometryDataHooks';
import { getProjectData } from 'state/jotai.projectDataHooks';

import { useBreak } from 'hooks/useBreak';

import { Space } from './Isometry.style';
import { Cursor } from './Isometry.Cursor';
import { createZppSetup } from './Isometry.ZoomPanPinch';
import { Canvas } from './Isometry.Canvas';
import { Center } from './Isometry.Center';
import { Tiny } from './Isometry.Tiny';
import { Svg } from './Isometry.Svg';
import { Controls } from './Isometry.Controls';
import { Measure } from './Isometry.Measure';
import { Recenter } from './isometry.Recenter';
import { Loading2 } from 'components/Loading2/Loading2';
import { ZoomPanPinch } from 'components/ZoomPanPinch/ZoomPanPinch';
import { Compas } from 'components/Compas/Compas';
import { ColorLegend } from 'components/ColorLegend/ColorLegend';
import { NoSnapSlider } from 'components/IsometryControls/NoSnapSlider';
import { Play } from 'components/IsometryRotation/Play';
import { SnapOnRotationEnd } from 'components/IsometrySnapOnRotationEnd/SnapOnRotationEnd';
import { Status } from 'components/StatusPopup/StatusPopup';
import { drawer_animation_done } from 'state/store.global';
import { Overview } from 'components/OverviewLabel/Overview';
import { _bootstrap } from 'state/store.bootstrap';
import { ViewMap } from 'pages/View.Map';
import { OverviewBack } from "./OverviewBack";
import { _project } from 'state/store.projects';
import styled from 'styled-components';
import { OldSnapSlider } from 'components/OldIsometryControls/OldSnapSlider';
import { BREAK_ } from 'constants';
import { DesktopColorLegend } from 'components/ColorLegend/DesktopColorLegend';
import { Box } from '@mui/material';

export const Isometry = () => {
  let timeout;
  const [measure, bounds] = useMeasure();
  const showOldSlider     = useGet(vis_showOldSlider);
  const [state, setState] = useState(false);

  const { pin, pid } = useGet(active_project);
  const projectType  = getFocus(_bootstrap, 'type');

  const showSVG                 = useGet(drawer_animation_done);
  const mode                    = true;

  const lowQualityFrames  = getLowIsoData('frames')
  const lowQualityPercent = getLowIsoData('percent')
  const lowQualityStatus  = getLowIsoData('status')
  const lowQualityConfig  = getLowIsoData('config')

  const tiny       = getIsoData('tiny', 'src');
  const frames     = getIsoData('frames');
  const percent    = getIsoData('percent');
  const status     = getIsoData('status');
  const config     = getIsoData('config');
  const frame      = getIsoData('activeframe');
  const svg        = getIsoData('overlays', frame);
  const index      = getIsoData('snapindex');
  const isrotating = useGet(iso_isrotating);
  const units      = getProjectData('units', 'Units');
  const setIndex   = setIsoData('snapindex');
  const setFrame   = setIsoData('activeframe');
  const setScale   = setFocus(_isometry, 'activescale');
  const scale      = getFocus(_isometry, 'activescale');
 
  const statusicon = "/Postillionen/logos/general_eido_logo/statuspopup.png";

  const zppRef          = useRef();
  const canRef          = useRef();
  const dim             = { W: lowQualityConfig?.IMAGE_W, H: lowQualityConfig?.IMAGE_H };
  const point           = lowQualityConfig?.CENTER;
  const zppsetup        = createZppSetup(bounds, zppRef, dim, setScale, mode, pin ,);
  const rmode           = useGet(iso_free);
  const isrotate        = useGet(iso_isrotating);
  const buttonsLabel    = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
  const isMobile        = useBreak('md_dn');
  const currentLanguage = useAtom(current_language)[0];

  const handleStatusPopup = () => {
    setState(!state);

  };
  const animation = {
    initial   : { opacity: 0 },
    animate   : { opacity: 1 },
    exit      : { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  function showTooltip(evt, ID) {
    const coords                = isMobile ? 0 : 0;
    let   tooltip               = document.getElementById("tooltip");
          tooltip.style.display = "flex";
          tooltip.style.left    = evt.clientX + coords + 'px';
          tooltip.style.top     = evt.clientY + coords + 'px';

    if (isMobile) {
      clearTimeout(timeout);
        // const timeoutValue = ID === "c6" ? 10000 : 10000;
      const timeoutValue = 10000;
            timeout      = setTimeout(() => {
        hideTooltip();
      }, timeoutValue);
    }
  }

  function hideTooltip() {
    var tooltip                         = document.getElementById("tooltip");
    if  (tooltip) tooltip.style.display = "none";
  }
  
  if (lowQualityConfig?.IMAGE_SNAPFRAMES?.length === 1) return <ViewMap />

  if (lowQualityConfig && pid && pin >= 0 && !Array.isArray(frame)) return (
    <>

      <AnimatePresence>
        <Tooltip id = "tooltip" as = {motion.div} {...animation} clr = {clr} className = "paragraph1-regular ">
          Kommer snart
        </Tooltip>
      </AnimatePresence>
      <Cursor>
        {
          <OverviewBack />}
        <SnapOnRotationEnd {...{ frame, setFrame, config, setIndex, rmode, isrotate, index }} />
        <Measure ref = {measure}>
          <ZoomPanPinch {...{ ref: zppRef, ...zppsetup ,}}>
           
          {
              status !== 'DONE' ?
              <>
               <Tiny {...{ frames: lowQualityFrames, frame, tiny, dim, status: lowQualityStatus, percent: lowQualityPercent }}/>
               <Canvas {...{ ref:canRef, frame, pid, status: lowQualityStatus, dim, frames: lowQualityFrames, config: lowQualityConfig }} />
              </>
              : 
              <>
                <Tiny {...{ frames, frame, tiny, dim, status, percent }}/>
                {/* <div
        style={{
          transform: `scale(${1 / (zppRef.current?.state.scale || 1)})`,
          transformOrigin: 'top left',
          width: '100%',
          height: '100%',
          pointerEvents: 'none', // Prevents interactions
          position: 'absolute', // Keeps canvas in place
        }}
      > */}
        <Canvas ref={canRef} {...{ frame, pid, status: lowQualityStatus, dim, frames: lowQualityFrames, config: lowQualityConfig }} />
      {/* </div> */}

              </>
            }
            <Center {...{ point }} />
            { !rmode && <Svg {...{setScale,scale, zppRef, svg, percent: lowQualityPercent,status: lowQualityStatus, units, showTooltip, hideTooltip ,}}/> }
            {/* <Labels {...{ frame, index, config, rmode, scale }}/> */}
            {/* <Overview {...{ frame, index, config, rmode, status }}/> */}
          </ZoomPanPinch>
        </Measure>
        {/* <Space d = {pin === 0 ? 0 : 76} m = {0} /> */}
        <Loading2 {...{ progress:lowQualityPercent, show:(lowQualityStatus !== 'DONE' || !units) && lowQualityFrames.length > 1 }} />
        <AnimatePresence>
          
          {
            lowQualityPercent == 100 && showOldSlider ? (
              <OldSnapSlider {...{ frame, setFrame, config: lowQualityConfig, frames: lowQualityFrames, index, setIndex }} />
            ) : (
              <>
                { rmode && <>
                  <NoSnapSlider {...{ frame, setFrame, config: lowQualityConfig, frames: lowQualityFrames, index, setIndex }}/>
                  { isrotate && <Play {...{ frame, setFrame, config: lowQualityConfig, setIndex }} /> }
                </> }
              </>
            )
          }
        </AnimatePresence>
        <FloatBtn rmode = {rmode} projectType = {projectType} pin = {pin}>
        <Recenter setScale={setScale} rmode               = {rmode}  z                   = {zppRef} bounds     = {bounds} dim      = {dim} pin = {pin} {...{percent}}/>
        <Compas   frame               = {frame} cfg                 = {config} label      = {buttonsLabel?.filter( btn => btn.UILabelProperty === 'Nordpil')[0]?.Label}  {...{percent}}/>
        </FloatBtn>
        
        {
          state && pin>=1 && !rmode && (
              <>
                <DesktopColorLegend />
              </>
            )}
          
          <StatusButton rmode={rmode} projectType={projectType} pin={pin}>
            {isMobile && pin >= 1 && lowQualityPercent == 100 && (
              <Box
                onClick={handleStatusPopup}
                component="img"
                src={statusicon}
                
              />
            )}
          </StatusButton>
      </Cursor>
    </>
  )
}

const StatusButton = styled.div`
position: absolute;
width:40px;
height:40px;
  ${BREAK_.md_dn} {
      bottom: 10px;
      left   : 16px;
       padding-right: 8px;
       cursor: pointer;
    }
`;
const FloatBtn = styled.div`
  position: absolute;
  top     : 28px;
  right   : ${({rmode}) => rmode ? 42 : 42 }px;
  display : flex;
  gap     : 8px;

    ${BREAK_.md_dn} {
      top  : ${({rmode,pin, projectType}) =>(pin > 0 || projectType === 'mono') && rmode ? '16px' :  '16px'};
      right: 18px;
    }
`

const Tooltip = styled(motion.div)`
  display        : none;
  height         : 39px;
  border-radius  : 8px;
  border         : 1px solid ${({ clr }) => `${clr?.primary}`};
  background     : ${({ clr }) => `${clr?.primary}db`};
  justify-content: center;
  align-items    : center;
  padding        : 8px 16px;
  position       : fixed;
  color          : #fff;
  z-index        : 1;
`;
