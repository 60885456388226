import { useEffect }        from 'react'
import { useFetch }         from 'hooks/fetch/useFetch'
import { setFocus, useGet } from 'state/jotai'
import { _isometry }        from 'state/store.isometry'
import { renderLayout }     from 'state/store.global'
import { preloadImage }     from 'utilities/utility.images'
import { ISO_CONFIG }       from 'api/api'
import eachOfLimit          from 'async/eachOfLimit'

export const PreloadFrames_Tiny = () => {
  const file     = 'PreloadingTiny.jsx'
  const url      = ISO_CONFIG
  const config   = useFetch({ url, file }).D
  const ready    = useGet(renderLayout)
  const setTiny  = setFocus(_isometry, 'tiny')
  const setFrame = setFocus(_isometry, 'activeframe')
  const setMode  = setFocus(_isometry, 'mode')
  useEffect(()=>{
    if ( config && ready ) {
      const len = config.length
      const arr = []
      const ini = []
      const mod = []
      eachOfLimit(config, len, 
        ( val, idx, callback ) => {
          const IMG = preloadImage(val.TINY)
          const PID = val.PROJECT_ID
          const INI = val.IMAGE_SNAPFRAMES[val.SLIDER_INITIAL]
          arr[idx] = ({[PID]:IMG})
          ini[idx] = ({[PID]:INI})
          mod[idx] = ({[PID]:true})
          setTiny( arr )
          
          setFrame( ini )
          setMode( mod )
          IMG.onload = () => callback()
        },
        () => {
          // console.log( 'Done preloading tiny frames' )
        }
      )
    }
  },[config, ready])
  return null
}