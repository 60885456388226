import { Box } from "@mui/material";
import { FlexBox, FooterButton, FullWidth, Section } from "./DualMenu.style";
import { useBreak } from "hooks/useBreak";
import { useLocation, useNavigate } from "react-router-dom";
import { getFocus, useAtom } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { Icon } from "assets/Icons_";
import { SelectFunction } from "components/SelectFunction/SelectFunction";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { Controls } from "components/Isometry/Isometry.Controls";
import { getIsoData, getLowIsoData, setIsoData } from "state/jotai.isometryDataHooks";
import { Status } from "components/StatusPopup/StatusPopup";
import { useTranslation } from "react-i18next";
import { currentActiveProject } from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { ArrowButton } from "components/Isometry/Isometry.Controls.Arrows";
import EusButton, { ETStyledButton } from "components/Button/EusButton";

export const IsometryDualMenu = ({
  left,
  right,
  nav,
  pin,
  pid,
  openSelectFunction,
  showSelectFunction,
  setShowSelectFunction,
  clickList,
  list,
  buttonsLabel,
  showProperty,
  setShowProperty,
  toggleDrawer,
  config,
  rotatemode,
  click2,
  turnBuilding,
  isrotate,
  click1,
  clickModel,
  model,
  filterShouldShow,
  clickFilter,
  filter,
  state,
  setState,
  closeDrawer,
  setShowFilter,
  setShowMenu,
  showOldSlider,
}) => {
  const type                       = getFocus(_bootstrap, "type");
  const navigate                   = useNavigate();
  const names                      = getFocus(_bootstrap, "projectnames");
  const {
    i18n: { language },
  } = useTranslation();
  const isStatus   = true;
  const isMobile   = useBreak("md_dn");
  const here       = useLocation().pathname;
  const isIsometry = here.includes("isometry");

  const frame            = getIsoData("activeframe");
  const frames           = getIsoData("frames");
  const index            = getIsoData("snapindex");
  const setIndex         = setIsoData("snapindex");
  const setFrame         = setIsoData("activeframe");
  const percent          = getIsoData("percent");
  const setActiveProject = useAtom(currentActiveProject)[1];

  const lowQualityPercent = getLowIsoData('percent')
  const lowQualityConfig  = getLowIsoData('config')

  const showButton = [
    "list",
    "compare",
    "favorites",
    "gallery",
    "downloads",
  ].some((i) => here.includes(i));

  const props = { type, isIsometry, pin, isMobile, pid };
  return (
    <>
      <FullWidth
        isMobile = {isMobile}
        left     = {left}
        right    = {right}
        nav      = {nav}
        clr      = {clr}
        isBgTrue = {true}
      >
        <FlexBox clr = {clr} isIsometry = {isIsometry}>
          {isMobile ? (
            <>
                {type !== "mono" && !rotatemode && isIsometry && (
                    <Box sx = {{ marginRight: '8px' }}>
                      <ArrowButton
                        variant = {"primary"}
                        clr     = {clr}
                        onClick = {()=> {
                              // if(showSelectFunction) {
                              //     setShowSelectFunction(false)
                              //     } else openSelectFunction()
                          // openSelectFunction()
                          setShowSelectFunction(true);
                          }
                      }
                        padding = "18px"
                      >
                       <span
                          style={{
                            fontSize: "22px",          // This will enlarge the icon
                            display : "inline-flex",   // Ensures proper alignment
                          }}
                        >
                          <Icon
                            icon     = "ellipsisvertical"
                            color    = {clr.primary_text}
                            isFaIcon = {true}
                            size     = "md"
                            wrap     = {{ transform: showSelectFunction ? 'rotate(90deg)' : 'rotate(0deg)'}}
                          />
                        </span>

                     
                      </ArrowButton>
                      <SelectFunction
                           click1                = {click1}
                           click2                = {click2}
                           isrotate              = {isrotate}
                           turnBuilding          = {turnBuilding}
                           rotatemode            = {rotatemode}
                           showSelectFunction    = {showSelectFunction}
                           setShowSelectFunction = {setShowSelectFunction}
                        />
                    </Box>
                )}
              {type === "poly" && rotatemode ? (
                   <EusButton
                   {...props}
                   clr         = {clr}
                   onClick     = {rotatemode && isrotate ? click1 : click2}
                   variant     = {"primary"}
                   icon        = {true}
                   iconName    = {isrotate ? "pause" : "play"}
                   isFaIcon    = {true}
                   buttonlabel = {false}
                   otherText   = {isrotate ? "Stop rotation" : "Start rotation"}
                   iconSize    = {"xl"}
                 />
              ) : type === "mono" && turnBuilding ? (
                <Section
                  padding = "0 30px"
                  {...props}
                  clr     = {clr}
                  onClick = {rotatemode && isrotate ? click1 : click2}
                >
                  <Icon
                    icon     = {isrotate ? "pause" : "play"}
                    color    = {clr?.primary_text}
                    isFaIcon = {true}
                    size     = "lg"
                  />{" "}
                  <p style = {{ color: clr?.primary_text }}>
                    {isrotate ? "Stop rotation" : "Start rotation"}
                  </p>
                </Section>
              ) : (
                <>
                  <Box
                    sx={{
                      display       : "flex",
                      justifyContent: isIsometry
                        ? "flex-start"
                                :   "space-between",
                      gap       : "8px",
                      position  : "relative",
                      alignItems: "center",
                      width     : "100%",
                    }}
                  >
                   
                    {isIsometry ? (
                      <EusButton
                        {...props}
                        clr         = {clr}
                        active      = {here.includes("list")}
                        onClick     = {clickList}
                        variant     = {"primary"}
                        iconName    = {"list"}
                        icon        = {true}
                        buttonText  = "Boligliste"
                        buttonlabel = {true}
                        iconSize    = {"xl"}
                        isFaIcon    = {true}
                        skipColorChange={true}
                      />
                    ) : type === "poly" && pin === 0 ? (
                      <EusButton
                        iconName    = {"modelover"}
                        icon        = {true}
                        clr         = {clr}
                        buttonlabel = {false}
                        otherText   = {"Oversigtskort"}
                        onClick     = {clickModel}
                        variant     = {"primary"}
                        pin         = {pin}
                        iconSize    = {"xl"}
                        showButton  = {showButton}
                        isFaIcon    = {true}
                        active      = {true}
                        skipColorChange={true}
                      />
                    ) : (
                      <EusButton
                        pin         = {pin}
                        clr         = {clr}
                        showButton  = {showButton}
                        active      = {true}
                        onClick     = {clickModel}
                        variant     = {"primary"}
                        iconName    = {"model"}
                        icon        = {true}
                        buttonText  = "isometri"
                        buttonlabel = {true}
                        iconSize    = {"25"}
                        isFaIcon    = {false}
                      />
                    )}
                  </Box>
                  {isIsometry && pin !==0 && (
                    <Box>
                      <Section width = "none">
                        {!rotatemode && lowQualityPercent == 100 && (
                          <>
                            {lowQualityConfig?.IMAGE_SNAPFRAMES?.length > 1 && pin <=2 && (
                              <Controls
                                {...{
                                  frame,
                                  setFrame,
                                  config: lowQualityConfig,
                                  frames,
                                  index,
                                  setIndex,
                                }}
                              />
                            )}
                          </>
                        )}
                      </Section>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Box
                sx={{
                           width                           : isMobile && !isIsometry ? "100%": "auto",
                           display                         : "flex",
                           justifyContent                  : 
                  isMobile && !isIsometry ? "space-between": "flex-start",
                           gap                             : "16px",
                           position                        : "relative",
                }}
              >
                { !turnBuilding && isIsometry &&
                <></>
                    // <ArrowButton
                    //     variant = {"primary"}
                    //     clr     = {clr}
                    //     padding = "18px"
                    //     onClick = {()=> {
                    //           // if(showSelectFunction) {
                    //           //     setShowSelectFunction(false)
                    //           //     } else openSelectFunction()
                    //       openSelectFunction()
                    //       }
                    //   }
                    //   >
                    //    <span
                    //       style={{
                    //         fontSize: "22px",          // This will enlarge the icon
                    //         display : "inline-flex",   // Ensures proper alignment
                    //       }}
                    //     >
                    //       <Icon
                    //         icon     = "ellipsisvertical"
                    //         color    = {clr.primary_text}
                    //         isFaIcon = {true}
                    //         size     = "md"
                    //         wrap     = {{ transform: showSelectFunction ? 'rotate(90deg)' : 'rotate(0deg)'}}
                    //       />
                    //     </span>

                    //     <SelectFunction
                    //         click1                = {click1}
                    //         click2                = {click2}
                    //         isrotate              = {isrotate}
                    //         turnBuilding          = {turnBuilding}
                    //         rotatemode            = {rotatemode}
                    //         showSelectFunction    = {showSelectFunction}
                    //         setShowSelectFunction = {setShowSelectFunction}
                    //     />
                    // </ArrowButton>
                }
                {turnBuilding ? (
                  <>
                  <EusButton
                   {...props}
                   clr         = {clr}
                   onClick     = {rotatemode && isrotate ? click1 : click2}
                   variant     = {"primary"}
                   icon        = {true}
                   iconName    = {isrotate ? "pause" : "play"}
                   isFaIcon    = {true}
                   buttonlabel = {false}
                   otherText   = {isrotate ? "Stop rotation" : "Start rotation"}
                   iconSize    = {"xl"}
                 />
                  </>
                ) : (
                  <>
                    {isIsometry ? (
                      <EusButton
                        {...props}
                        clr         = {clr}
                        active      = {here.includes("list")}
                        onClick     = {clickList}
                        variant     = {"primary"}
                        iconName    = {"list"}
                        icon        = {true}
                        buttonText  = "Boligliste"
                        buttonlabel = {true}
                        iconSize    = {"xl"}
                        isFaIcon    = {true}
                        skipColorChange={true}
                      />
                    ) : type === "poly" && pin === 0 ? (
                      <EusButton
                        iconName    = {"modelover"}
                        icon        = {true}
                        clr         = {clr}
                        buttonlabel = {false}
                        otherText   = {"Oversigtskort"}
                        onClick     = {clickModel}
                        variant     = {"primary"}
                        pin         = {pin}
                        iconSize    = {"xl"}
                        showButton  = {showButton}
                        isFaIcon    = {true}
                        active      = {true}
                        skipColorChange={true}
                      />
                    ) : (
                      <EusButton
                        pin         = {pin}
                        clr         = {clr}
                        showButton  = {showButton}
                        active      = {true}
                        onClick     = {clickModel}
                        variant     = {"primary"}
                        iconName    = {"model"}
                        icon        = {true}
                        buttonText  = "isometri"
                        buttonlabel = {true}
                        iconSize    = {"25"}
                        isFaIcon    = {false}
                        skipColorChange={true}
                      />
                    )}
                    {filterShouldShow && !isMobile && (
                          // <FooterButton
                          //   tabIndex={"0"}
                          //   role="button"
                          //   variant={"primary"}
                          //   pin={pin}
                          //   clr={clr}
                          //   showButton={showButton}
                          //   onClick={clickFilter}
                          // >
                          //   <Icon
                          //     color={clr.primary_text}
                          //     {...filter}
                          //     isFaIcon={true}
                          //   />
                          //   <div
                          //     className="eus-button-2"
                          //     style={{ color: clr?.primary_text }}
                          //   >
                          //     {
                          //       buttonsLabel?.filter(
                          //         (btn) => btn.UILabelProperty === "Anvendfilter"
                          //       )[0]?.Label
                          //     }
                          //   </div>
                          // </FooterButton>
                      <EusButton
                        iconName    = {"filters"}
                        icon        = {true}
                        clr         = {clr}
                        buttonText  = "Anvendfilter"
                        buttonlabel = {true}
                        onClick     = {clickFilter}
                        variant     = {"primary"}
                        pin         = {pin}
                        showButton  = {showButton}
                        iconSize    = {"xl"}
                        isFaIcon    = {true}
                        skipColorChange={true}
                      />
                    )}
                    {/* UnComment this whenever we have all property on else comment ShiftProperty component section */}
                    {type === "poly" && pin > 0 && (
                      <EusButton
                        iconName    = {"modelover"}
                        icon        = {true}
                        clr         = {clr}
                        buttonlabel = {false}
                        otherText   = {"Oversigtskort"}
                        onClick     = {() => {
                          closeDrawer("detail");
                          setShowFilter(false);
                          setShowMenu(false);
                          setActiveProject("");
                          navigate(
                            type == "mono"
                              ? `/${language}/isometry`
                              :   `/${language + names[language][0].path}`
                          );
                        }}
                        variant    = {"primary"}
                        pin        = {pin}
                        iconSize   = {"xl"}
                        showButton = {showButton}
                        isFaIcon   = {true}
                        active     = {true}
                        skipColorChange={true}
                      />
                    )}

                    {/* remove this pin when we have property more than 1 */}
                    {type === "poly" && (
                          // <EusButton
                          //   iconName={"buildings"}
                          //   icon={true}
                          //   clr={clr}
                          //   buttonlabel={false}
                          //   otherText={" Skift ejendom"}
                          //   onClick={() => {
                          //     setShowProperty(!showProperty);
                          //     toggleDrawer();
                          //   }}
                          //   variant={"primary"}
                          //   pin={pin}
                          //   iconSize={"xl"}
                          //   showButton={showButton}
                          //   isFaIcon={true}
                          //   active={true}
                          //   iconColor={
                          //     pin > 0 || showButton ? "#fff" : clr?.primary_text
                          //   }
                          // >
                          //   <ShiftProperty
                          //     showProperty={showProperty}
                          //     setShowProperty={setShowProperty}
                          //   />
                          // </EusButton>

                      <ETStyledButton
                        variant = {"primary"}
                        pin     = {pin}
                        skipColorChange={true}
                        onClick = {() => {
                          if (!showProperty) {
                            setShowProperty(true);
                            toggleDrawer();
                          } else {
                            setShowProperty(false);
                            toggleDrawer();
                            const buttons = document.querySelectorAll("button");
                            buttons.forEach((button) => button.blur());
                          }
                              // setShowProperty(!showProperty);
                              // toggleDrawer();
                        }}
                        clr        = {clr}
                        showButton = {showButton}
                      >
                        <Icon
                          icon  = "buildings"
                          size  = "lg"
                          // color = {
                          //   pin > 0 || showButton ? "#000": clr?.primary_text
                          // }
                          color = {clr?.primary_text}
                          isFaIcon = {true}
                          skipColorChange={true}
                        />
                        <div
                          className = "eus-button-2"
                          style     = {{ color: clr?.primary_text }}
                        >
                          Skift ejendom
                        </div>
                        <ShiftProperty
                          showProperty    = {showProperty}
                          setShowProperty = {setShowProperty}
                        />
                      </ETStyledButton>
                    )}

                    {type === "poly" && pin !== 0 && (
                      <ETStyledButton
                        variant      = {isMobile ? "secondary" : "primary"}
                        isStatus     = {isStatus}
                        pin          = {pin}
                        clr          = {clr}
                        onClick      = {() => isMobile && setState(!state)}
                        onMouseEnter = {() => !isMobile && setState(true)}
                        onMouseLeave = {() => !isMobile && setState(false)}
                      >
                        <Status
                          pin      = {pin}
                          state    = {state}
                          setState = {setState}
                          isMobile = {isMobile}
                          {...{ percent: lowQualityPercent }}
                        />
                      </ETStyledButton>
                    )}
                    {type === "mono" && (
                      <ETStyledButton
                        variant      = {isMobile ? "secondary" : "primary"}
                        isStatus     = {isStatus}
                        pin          = {pin}
                        clr          = {clr}
                        onClick      = {() => isMobile && setState(!state)}
                        onMouseEnter = {() => !isMobile && setState(true)}
                        onMouseLeave = {() => !isMobile && setState(false)}
                      >
                        <Status
                          pin      = {pin}
                          state    = {state}
                          setState = {setState}
                          isMobile = {isMobile}
                          {...{ percent: lowQualityPercent }}
                        />
                      </ETStyledButton>
                    )}
                  </>
                )}
              </Box>
              {!turnBuilding && isIsometry && (
                <Box>
                  <Section width = "none">
                    {!rotatemode && lowQualityPercent == 100 && (
                      <>
                        {lowQualityConfig?.IMAGE_SNAPFRAMES?.length > 1 && pin <=2 && (
                          <Controls
                            {...{
                              frame,
                              setFrame,
                              config: lowQualityConfig,
                              frames,
                              index,
                              setIndex,
                            }}
                          />
                        )}
                      </>
                    )}
                  </Section>
                </Box>
              )}
            </>
          )}
        </FlexBox>
        <PoweredBy clr = "#000" padding = { isMobile ? "0px 0 16px 0" : "0px 0 8px 0"} />
      </FullWidth>
    </>
  );
};
